import styles from "./fees.module.css";
import img1 from "_customization/assets/project-icon.png";
import FeeDiscounts from "./fee-discounts";
import { useEffect, useState } from "react";
import useStats from "hooks/useStats";
import { isNumeric, numberWithCommas } from "utils/libs";
import { useSelector } from "react-redux";
import useBalance from "hooks/useBalance";
import Ads from "components/ads/Ads";
import Help from "components/help/help";
import { useTranslation } from "react-i18next";
import projectConfig from '_customization/project-config'
import PageName from 'components/_shared/page-name/PageName'

function Fees() {
  const {t} =useTranslation();
  const userAddress = useSelector((state) => state.userAddress);
  const web3 = useSelector((state) => state.web3);
  const balanceUpdate = useSelector((state) => state.balanceUpdate);
  const { getAnchorBalance } = useBalance();
  const [anchorBalance, setAnchorBalance] = useState(null);
  const { getVolume, getTotalFees } = useStats();
  const [tradingVolume, setTradingVolume] = useState();
  const [totalFees, setTotalFees] = useState();
  useEffect(() => {
    if (web3) {
      getVolume().then((res) => {
        if (res && isNumeric(res)) setTradingVolume(res);
      });
      getTotalFees().then((res) => {
        if (res && isNumeric(res)) setTotalFees(res);
      });
    }
  }, [web3]);
  useEffect(() => {
    if (web3 && userAddress) {
      getAnchorBalance().then((res) => {
        if (res) setAnchorBalance(res);
      });
    }
  }, [userAddress, balanceUpdate, web3]);
  return (
    <div className={styles.container}>
      <PageName
          title={t('Fees.Fees_text')}
          shortMessage={t('Fees.shortMessage_text')}
          longMessage={t('Fees.longMessage_text')}
          text={`${t('Fees.Hold_object.hold')} ${projectConfig.tokenName} ${t('Fees.Hold_object.tokenAndPay')} ${projectConfig.appName} ${t('Fees.Hold_object.platform')}`}
      />
      <div className={styles.section2}>
        <div className={styles.s1}>
          <div>{t('Fees.Fees_text')} (30d)</div>
          <div className={styles.volume}>${numberWithCommas(totalFees)}</div>
          <div>&nbsp;</div>
        </div>
        <div className={styles.s2}>
          <div>{t('Fees.volume_text')}</div>
          <div className={styles.volume}>
            {tradingVolume &&
              numberWithCommas(Number(tradingVolume)?.toFixed(2))}
          </div>
          <div>{t('Fees.exchangeinlast_text')}</div>
        </div>
        <div className={styles.s3}>
          <div className={styles.s221}>
            <img src={img1} className={styles.logo1} />
            <div>{t('Fees.walletBalance_text')}</div>
          </div>
          <div className={styles.volume}>{numberWithCommas(anchorBalance)}</div>
          <div className={styles.ex1}>&nbsp;</div>
        </div>
      </div>
      <div className={styles.section3}>
        <div className={styles.s31}>
          <FeeDiscounts anchorBalance={anchorBalance} />
        </div>
        <div className={styles.s32}>
          <Ads/>
        </div>
      </div>
    </div>
  );
}
export default Fees;
