import classNames from 'classnames';
import TokenIcon from 'components/tokenIcon/tokenIcon';
import styles from './markets-table.module.css'
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';
import { ReactComponent as LeftIcon } from 'assets/left.svg';
import { ReactComponent as RightIcon } from 'assets/right.svg';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState, useTransition } from 'react';
import { numberWithCommas } from 'utils/libs'
import { useTranslation } from 'react-i18next';

function MarketsTable({ pools }) {
    const {t} = useTranslation();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
    const [columnIsVisible, setcolumnIsVisible] = useState([true, true, true, true]);
    useEffect(() => {
        if (isSmallScreen) {
            setcolumnIsVisible([true, true, false, false])
        }
    }, [])
    function changePage() {
        setcolumnIsVisible(columnIsVisible.map(v => !v));
    }

    return <div className={styles.container}>
        <div className={styles.tableBody}>
            <div className={classNames(styles.row, styles.header)}>
                <div className={styles.col}>{t('markets-table.Market_text')}</div>
                {columnIsVisible[0] && <div className={styles.col}>{t('markets-table.index_text')}</div>}
                {columnIsVisible[1] && <div className={styles.col}>{t('markets-table.change_text')}</div>}
                {columnIsVisible[2] && <div className={styles.col}>{t('markets-table.funding_text')}</div>}
                {columnIsVisible[3] && <div className={styles.col}>{t('markets-table.OpenInterest_text')}</div>}
            </div>
            {pools.map((row, ix) => {
                let currentPriceFD = 2
                if (row.currentPrice < 0.01) {
                    currentPriceFD += 2
                } else if (row.currentPrice < 0.001) {
                    currentPriceFD += 1
                }
                return (
                    <div key={ix}>
                        <div className={classNames(styles.row)}>
                            <div className={classNames(styles.col, styles.col1)}>
                                <TokenIcon className={styles.icon} tokenName={row.token} />
                                <div>
                                    <div className='whiteColor'>{row.token}</div>
                                    <div className={styles.fontWeight400}>USD</div>
                                </div>
                            </div>
                            {columnIsVisible[0] && <div className={classNames(styles.col, 'whiteColor')}>
                                ${numberWithCommas(row.currentPrice?.toFixed(currentPriceFD))}
                            </div>}
                            {columnIsVisible[1] && <div className={styles.col}>
                                <div className={classNames(row.changePercentage > 0 ? styles.pos : styles.neg, styles.changeParent)}>
                                    {row.changePercentage < 0 && <ArrowDownIcon className={styles.pnIcon} />}
                                    {row.changePercentage >= 0 && <ArrowUpIcon className={styles.pnIcon} />}
                                    <div>{row.changePercentage?.toFixed(2)}%</div>
                                </div>
                                <div className={styles.fontWeight400}>
                                    ${numberWithCommas(row.priceChange?.toFixed(2))}
                                </div>
                            </div>}
                            {columnIsVisible[2] && <div className={classNames(styles.col, styles.pos)}>{row.dailyInterest}%</div>}
                            {columnIsVisible[3] && <div className={styles.col}>
                                <div className={styles.row1}>
                                    <div className='whiteColor'>{numberWithCommas(row.openInterestToken?.toFixed(2))}</div>
                                    <div className={styles.symbolBox}>{row.token}</div>
                                </div>
                                <div className={styles.fontWeight400}>${numberWithCommas(row.openInterestUSD?.toFixed(2))}</div>
                            </div>}
                        </div>
                        <div className='horizontalSeparator'></div>

                    </div>
                )
            })}
        </div>
        <div className={styles.pageNav}>
            {(columnIsVisible[0] && columnIsVisible[1]) && <RightIcon className={styles.pageNavIcon} onClick={() => changePage()} />}
            {(columnIsVisible[2] && columnIsVisible[3]) && <LeftIcon className={styles.pageNavIcon} onClick={() => changePage()} />}
        </div>
    </div>;
}

export default MarketsTable;