import { useState, useEffect } from 'react'
import styles from './fee-level-popup.module.css';
import triangle from 'assets/triangle.png';
import classNames from 'classnames';
import { ReactComponent as Xmark } from 'assets/xmark.svg';
import { useNavigate } from "react-router-dom";
import pathNames from 'utils/path-names';
import { useTranslation } from 'react-i18next'
import { handleSetTranslatedStyle } from 'utils/shared-methods'

function FeeLevelPopup({ level, onClose }) {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const [translatedStyle, setTranslatedStyle] = useState('');

    // Important!: pushes translation
    useEffect(() => {
        handleSetTranslatedStyle(setTranslatedStyle)
    }, [window.localStorage.getItem('i18nextLng')])

    return <div className={styles.container}>
        <div className={styles.arrow} >
            <img src={triangle} className={styles.img} />
        </div>
        <div className={styles[`content${translatedStyle}`]}>
            <Xmark className={styles.xmark} onClick={() => onClose()} />
            <div className={styles.txt1}>
                {t("feeLevelPopup.current") + ' '}
                <span onClick={() => { onClose(); navigate(pathNames.fees); }} className={styles.link}>{t("feeLevelPopup.feeLevel")}</span>
                {' ' + t("feeLevelPopup.is") + ' '}
                <span className={styles.number}>{level}</span>
            </div>
            <div onClick={() => { onClose(); navigate(pathNames.fees); }} className={classNames(styles.txt2, styles.link)}>
                {t("feeLevelPopup.learnAboutFees")}
            </div>
        </div>
    </div>
}
export default FeeLevelPopup;