export default {
    /*The files should be manually changed ("ANCHOR"):
    * 1) contract.js
    * 2) config.json
    * 3) usePrice, useSwap, useBalance, usePool, useSwap
    * 4) select-token.js, lending.js, open-position.js
    * 5) tokenIcon.js
    * */

    appName: "AnchorExchange",
    tokenName: "ANCHOR",
    parentWebsiteName: "AnchorFinance",
    supportEmail: "support@anchorfinance.io",
    companyName: "Chiffre IT Solutions",

    configs: {
        topHeader: false, /*true | false*/
        tokenHold: true, /*true | false*/
    },

    externalLinks: {
        website: "https://www.anchorfinance.io",
        docs: "https://docs.anchorexchange.io",
        docsTutorial: "https://docs.anchorexchange.io/tutorial",
        terms: "https://www.anchorfinance.io/terms",
        dataPrivacy: "https://anchorfinance.io/data-privacy/",

        /*To modify: change link to '' or null*/
        telegram: "https://t.me/anchorswap",
        twitter: "https://twitter.com/anchor_finance",
        instagram: "https://www.instagram.com/anchorfinance/",
        youtube: "https://www.youtube.com/c/AnchorFinance",
        medium: "https://medium.com/@anchorfinance",
        chart: "https://www.dextools.io/app/bnb/pair-explorer/0x942986b6cbe26a80a5456d5d3ac75860f0e9546e",
    },

    api: {
        getVolumeData: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/volume24",
        getNumPositions: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/positions24",
        getTotalFees: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserFee?user=",
        getOpenPositions: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getOpenPositions",
        getUserOpenPositions: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserOpenPositions?user=",
        getUserClosedPositions: "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserClosedPositions?user=",
        getCurrentTokenPrice: "https://api.anchorswap.app/api/pair/anchorValue",
        getRefShare: "https://apidev.anchorlink.io/api/wallet/refshare/get?wallet=",
        postNewRefShare: "https://apidev.anchorlink.io/api/wallet/refshare",
    }
}