import styles from './sidebar.module.css';
import className from "classnames"
import youtube from 'assets/youtube.png'
import telegram from 'assets/telegram.png'
import twitter from 'assets/twitter.png'
import medium from 'assets/medium.png'
import overview from 'assets/overview.png'
import { ReactComponent as PositionHistoryIcon } from 'assets/position_history.svg';
import { ReactComponent as FeesIcon } from 'assets/fees.svg';
import { ReactComponent as RefIcon } from "assets/referral-overview-icon.svg";
import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import openMenu from 'assets/open-menu.png'
import { useDispatch, useSelector } from 'react-redux';
import pathNames from 'utils/path-names';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import copy from 'copy-to-clipboard';
import {toast, ToastContainer} from "react-toastify";
import pathDependentLinks from 'utils/path-dependent-links'
import projectConfig from '_customization/project-config'
import { handleSetTranslatedStyle, removeNewLinesOnString } from 'utils/shared-methods'

function Sidebar() {
    const showSidebar = useSelector((state) => state.showSidebar);
    const pathname = useLocation().pathname;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { externalLinks } = projectConfig

    const userAddress = useSelector((state) => state.userAddress);
    const web3 = useSelector((state) => state.web3);
    const [refLinkText, setRefLinkText] = useState(t("referralOverview.noRefLink_text"));
    const [refLink, setRefLink] = useState('');
    const [translatedStyle, setTranslatedStyle] = useState('');

    // Important!: pushes translation
    useEffect(() => {
        handleSetTranslatedStyle(setTranslatedStyle)
        if (!userAddress) {
            setRefLinkText(t("referralOverview.noRefLink_text"))
        } else if (web3 && userAddress) {
            setRefLinkText(t("referralOverview.referralLink_text"));

            const link = pathDependentLinks.refLinkSkeleton + userAddress;
            const linkFormatted = removeNewLinesOnString(link);
            setRefLink(linkFormatted)
        }
    }, [window.localStorage.getItem('i18nextLng'), userAddress, web3])
    useEffect(() => {
        if (pathname == pathNames.margin) {
            if (!showSidebar)
                dispatch({ type: "showSidebarChange", showSidebar: true });
        } else {
            if (showSidebar)
                dispatch({ type: "showSidebarChange", showSidebar: false });
        }
    }, [pathname]);
    function onCopy() {
        if (Boolean(userAddress)) {
            try {
                copy(refLink);
                toast.success(t("referralOverview.refLinkCopied_text"));
            } catch {
                return 0;
            }
        } else {
            toast.error(t("referralOverview.connectWallet_text"));
        }

    }

    if (!showSidebar)
        return <div className={styles.closeContainer} >
            <div className={styles.m1} onClick={() => { dispatch({ type: "showSidebarChange", showSidebar: true }); }}>
                <img className={styles.im1} src={openMenu} />
            </div>
        </div>
    else
        return <div className={styles.openContainer} >
            <div className={styles.refContainer}>
                <div className={styles.inputContainer}>
                    <RefIcon className={styles.refIcon}/>
                    <div className={styles.refLinkText}>{refLinkText}</div>
                </div>
                <button className={styles.copyBtn} onClick={onCopy}>
                    <CopyIcon className={styles.copyIcon}/>
                    <ToastContainer
                        position="top-center"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </button>
            </div>
            <div>&nbsp;</div>
            <div className={styles.r1}>
                <div
                    className={styles[`btnHide${translatedStyle}`]}
                    onClick={() => { dispatch({ type: "showSidebarChange", showSidebar: false }); }}
                >
                    {t('sidebar.Hide_text')}
                </div>
            </div>
            <Link to={pathNames.margin}>
                <div className={className(styles.item, pathname == pathNames.margin && styles.selectedItem)}>
                    <img className={styles.itemImage} src={overview} />
                    <div>{t('sidebar.Overview_text')}</div>
                </div>
            </Link>
            <Link to={pathNames.positions_and_history}>
                <div className={className(styles.item, pathname == pathNames.positions_and_history && styles.selectedItem)}>
                    <PositionHistoryIcon className={styles.itemImage} />
                    {t('sidebar.Positions_text')} & {t('sidebar.History_text')}
                </div>
            </Link>
            {projectConfig.configs.tokenHold &&
                <Link to={pathNames.fees}>
                    <div className={className(styles.item, pathname == pathNames.fees && styles.selectedItem)}>
                        <FeesIcon className={styles.itemImage}/>
                        {t('sidebar.Fees_text')}
                    </div>
            </Link>}
            <Link to={pathNames.referral_overview}>
                <div className={className(styles.item, pathname == pathNames.referral_overview && styles.selectedItem)}>
                    <RefIcon className={styles.itemImage} />
                    {t('sidebar.Referral_text')} {t('sidebar.Overview_text')}
                </div>
            </Link>
            <div className={styles.growSpace}></div>
            <div>
                {/*<img className={styles.certik} src={certikIcon} />*/}
            </div>
            <div className={styles.social}>
                {externalLinks.twitter &&
                    <a href={externalLinks.twitter} target="_blank">
                        <img src={twitter} className={styles.socialImage} />
                    </a>
                }
                {externalLinks.telegram &&
                    <a href={externalLinks.telegram} target="_blank">
                        <img src={telegram} className={styles.socialImage}/>
                    </a>
                }
                {externalLinks.medium &&
                    <a href={externalLinks.medium} target="_blank">
                        <img src={medium} className={styles.socialImage}/>
                    </a>
                }
                {externalLinks.youtube &&
                    <a href={externalLinks.youtube} target="_blank">
                        <img src={youtube} className={styles.socialImage}/>
                    </a>
                }
            </div>
        </div>
}
export default Sidebar;