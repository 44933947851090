import styles from "./chart.module.css"
import React, { useEffect, useRef, useState } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import { useSelector } from "react-redux";
function Chart() {
  //"0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE"

  const selectedPool = useSelector((state) => state.selectedPool);

  return <iframe className={styles.iframe} src={"https://dexscreener.com/bsc/" + selectedPool.chartId + "?embed=1&theme=dark&trades=0&info=0"}></iframe>;


  //return <div className={styles.chart}><TradingChart /></div>
}
export default Chart;


const TradingChart = () => {
  const tradingRef = useRef(null);

  const selectedPool = useSelector((state) => state.selectedPool);


  useEffect(() => {

  }, []);

  return (
    <TradingViewWidget
      symbol={selectedPool?.chartSymbol ?? "BUSD"}
      theme={Themes.DARK}
      locale="en"
      ref={tradingRef}
      autosize

    />
  );
};

