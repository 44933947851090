import { ReactComponent as CopyIcon } from 'assets/copy-icon.svg';
import styles from "./referral-overview.module.css";
import ReferralTable from "./referral-table";
import { useEffect, useState } from "react";
import { numberWithCommas } from "utils/libs";
import { useSelector } from "react-redux";
import useBalance from "hooks/useBalance";
import useReferrals from "hooks/useReferrals";
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Ads from "components/ads/Ads";
import { useTranslation } from "react-i18next";
import pathDependentLinks from 'utils/path-dependent-links'
import PageName from 'components/_shared/page-name/PageName'
import projectConfig from '_customization/project-config'
import { removeNewLinesOnString } from 'utils/shared-methods'

function ReferralOverview() {
  const { t } = useTranslation();
  const userAddress = useSelector((state) => state.userAddress);
  const web3 = useSelector((state) => state.web3);
  const { getAnchorBalance } = useBalance();
  const { getRefShare } = useReferrals();
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const [refLink, setRefLink] = useState(t("referralOverview.connectWallet_text"));
  const [referralsArray, setReferralsArray] = useState(null);

  // Important!: pushes translation
  useEffect(() => {
    if (!userAddress) setRefLink(t("referralOverview.noRefLink_text"))
  }, [window.localStorage.getItem('i18nextLng')])
  useEffect(() => {
    if (web3) {
      getRefShare().then((res) => {
        if (res && Boolean(res.success)) {
          setTotalCommission(res.info.referral);
          setTotalReferrals(res.info.total);
          setReferralsArray(res.info.linked);
        }
      });
    }
  }, [web3]);
  useEffect(() => {
    if (userAddress) {
      const link = pathDependentLinks.refLinkSkeleton + userAddress;
      const linkFormatted = removeNewLinesOnString(link);
      setRefLink(linkFormatted);
    }
  }, [userAddress, web3]);
  function onCopy() {
    try {
      copy(refLink);
      toast.success(t("referralOverview.refLinkCopied_text"));
    } catch {
      return 0;
    }
  }

  return (
    <div className={styles.container}>
      <PageName
          title={t('referralOverview.referrals_text')}
          titleLong={t('referralOverview.referralOverview_text')}
          text={t('referralOverview.earn_text')}
      />
      <div className={styles.section2}>
        <div className={styles.s1}>
          <div>{t('referralOverview.referralLink_text')}</div>
          <div className={styles.refLinkContainer}>
            <span className={styles.refLink}>{refLink}</span>
            {Boolean(userAddress) &&
                <button className={styles.copyBtn} onClick={onCopy}>
                  <CopyIcon className={styles.copyIcon}/>
                  <ToastContainer
                      position="top-center"
                      autoClose={2500}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                  />
                </button>
            }
          </div>
          <div className={styles.ex1}>&nbsp;</div>
        </div>
        <div className={styles.s2}>
          <div>{t('referralOverview.totalReferrals_text')}</div>
          <div className={styles.s_description}>{totalReferrals}</div>
          <div className={styles.ex1}>&nbsp;</div>
        </div>
        <div className={styles.s3}>
          <div className={styles.s221}>
            <div>{t('referralOverview.totalcommission_text')}</div>
          </div>
          <div className={styles.s_description}>${numberWithCommas(totalCommission)}</div>
          <div className={styles.ex1}>&nbsp;</div>
        </div>
      </div>
      <div className={styles.section3}>
        <div className={styles.s31}>
          <ReferralTable referralsArray={referralsArray} />
        </div>
        <div className={styles.s32}>
          {projectConfig.configs.tokenHold && <Ads/>}
        </div>
      </div>
    </div>
  );
}
export default ReferralOverview;
