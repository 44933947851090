import { ReactComponent as DownIcon } from 'assets/arrow_circle_indicator_down.svg';
import { ReactComponent as UpIcon } from 'assets/arrow_circle_indicator_up.svg';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styles from './referral-table.module.css';
import {numberWithCommas } from 'utils/libs';
import { useTranslation } from 'react-i18next';

function ReferralTable({ referralsArray }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
    const [expanded, setExpanded] = useState(!isSmallScreen);
    const dispatch = useDispatch();

    return <div className={styles.container}>
        <div className={styles.h1}>
            <div>{t('referralTable.referrals_text')}</div>
            <div className={styles.expandIconParent}>
                {expanded ?
                    <UpIcon className={styles.expandIcon} onClick={() => setExpanded(false)} />
                    : <DownIcon className={styles.expandIcon} onClick={() => setExpanded(true)} />}
            </div>
        </div>
        {expanded && <>

            <div className={classNames(styles.tbheader)}>
                <div className={classNames(styles.item, styles.h3)}>
                    <div className={styles.item1}>{t('referralTable.wallet_text')}</div>
                    <div className={classNames(styles.item2, styles.h2)}>{t('referralTable.openingFees_text')}</div>
                    <div className={classNames(styles.item2, styles.h2)}>{t('referralTable.commission_text')}</div>
                </div>
            </div>

            {referralsArray ? (
                referralsArray.map((item, ix) => {
                    return <div key={ix} className={styles.itm} >
                        <div className={classNames(styles.item)}>
                            <div className={styles.item1}>{item.wallet}</div>
                            <div className={styles.item2}>${numberWithCommas(item.fees)}</div>
                            <div className={styles.item2}>${numberWithCommas(item.commission)}</div>
                        </div>
                    </div>
                })
            ) : (
                <div className={styles.noitm} >{t('referralTable.noReferrals_text')}</div>
            )}</>}

    </div>
}
export default ReferralTable;