import { useSelector } from "react-redux";

const useExecuteContract = () => {
    const web3wallet = useSelector(state => state.web3);
    const userAddress = useSelector(state=>state.userAddress);


    //options= { contract, functionName: string, args: array, getPromise: boolean}
    const callContract = async (options) => {
        if(!options.contract) return [{error:'no contract'}, null];
        if(!options.functionName) return [{error:'no function defined'}, null];

        const args = options.args || [];
        const call = options.contract.methods[options.functionName](...args).call();

        if(options.getPromise) return call;

        try{
            const result = await call;
            return [null, result];
        }
        catch(err){
            return [err, null];
        }
    }

    //data = {value, arguments, functionName}
    const sendContract  = async (contract, data) => {
        if(!contract) return [{error:'no contract'}, null];

        const txOptions = {from: userAddress};

        if(data.value > 0){
            txOptions.value = data.value;
        }
    
        let err = null; 
        const args = data.arguments ? data.arguments : [];

        // try{
        //     await contract.methods[data.functionName](...args).estimateGas(txOptions);
        // }
        // catch(error){
        //     console.log('estimate error', error.message);
        //     return {status:false, message:error.message, txlink:''};
        // }

        const tx = await contract.methods[data.functionName](...args).send(txOptions)  
        .catch((error) => {
            err = error;
            console.log(error.message);
            return {status:false, message:error.message, txlink:''};
        });
        
        if(err == null)
            return {status:true, message:'success', txlink:getTxLink(tx.transactionHash)};
        else 
            return {status:false, message:err.message, txlink:''};
    }

    const getTxLink = (hash) => {
        return "https:/bscscan.com/tx/"+hash;
    }

    return { callContract, sendContract, getTxLink }
}
    
export default useExecuteContract
    