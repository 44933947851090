import { ReactComponent as DownIcon } from 'assets/arrow_circle_indicator_down.svg';
import { ReactComponent as UpIcon } from 'assets/arrow_circle_indicator_up.svg';
import img1 from '_customization/assets/project-icon.png';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { calcLevel, feeDiscountLevels } from 'utils/feeDiscountLevel';
import pathNames from 'utils/path-names';
import styles from './fee-discounts.module.css';
import {numberWithCommas } from 'utils/libs';
import { useTranslation } from 'react-i18next';

function FeeDiscounts({ anchorBalance }) {
    const {t} = useTranslation()
    const navigate = useNavigate();
    var currentTier = calcLevel(anchorBalance);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
    const [expanded, setExpanded] = useState(!isSmallScreen);
    const dispatch = useDispatch();
    
    function onUpgrade() {

        const next = feeDiscountLevels.find(x => x.tier === (currentTier + 1))?.balance;
        if (next && anchorBalance && anchorBalance >= 0 && next >= 0) {
            dispatch({ type: "feeLevelUpgradeAmountChange", feeLevelUpgradeAmount: (next - anchorBalance) });
            navigate(pathNames.exchange);
        }
    }
    return <div className={styles.container}>
        <div className={styles.h1}>
            <img src={img1} className={styles.logo1} />
            <div>{t('FeeDiscount.FeeDiscount_text')}</div>
            <div className={styles.expandIconParent}>
                {expanded ?
                    <UpIcon className={styles.expandIcon} onClick={() => setExpanded(false)} />
                    : <DownIcon className={styles.expandIcon} onClick={() => setExpanded(true)} />}
            </div>
        </div>
        {expanded && <>

            <div className={classNames(styles.tbheader)}>
                <div className={classNames(styles.item, styles.h3)}>
                    <div className={styles.item1}>{t('FeeDiscount.tier_text')}</div>
                    <div className={classNames(styles.item2, styles.h2)}>
                        <img src={img1} className={styles.logo} />
                        <span>{t('FeeDiscount.balance_text')}</span>
                    </div>
                    <div className={styles.item3}>{t('FeeDiscount.discount_text')}</div>
                    <div className={styles.item4}></div>
                </div>
            </div>

            {expanded && feeDiscountLevels.map((item, ix) => {
                return <div key={ix} className={styles.itm} >
                    <div className={classNames(styles.item, anchorBalance && currentTier == item.tier && styles.selectedItem)}>
                        <div className={styles.item1}>{item.tier}</div>
                        <div className={styles.item2}>≥ &nbsp;{numberWithCommas(item.balance)}</div>
                        <div className={styles.item3}>{item.discount}%</div>
                    </div>
                    <div className={styles.item4}>
                        {anchorBalance && (currentTier + 1) == item.tier && <button onClick={onUpgrade} className={styles.upgrade}>{t('FeeDiscount.upgrade_text')}</button>}
                    </div>
                </div>
            })}</>}

    </div>
}
export default FeeDiscounts;