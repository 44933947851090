import { useSelector } from "react-redux";
import useExecuteContract from "./useExecuteContract";
import { rpcURL } from "../constants/rpcs";
import {
  pools,
  tokens,
  tokenToAddress,
  baseTokenAddress,
  baseTokenPoolAddress,
  configAddress,
} from "../constants/contracts";
import abi from "../constants/ABI/pool.json";
import configAbi from "../constants/ABI/config.json";
import erc20abi from "../constants/ABI/erc20.json";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import usePosition from "./usePosition";

const usePool = () => {
  const { callContract, sendContract } = useExecuteContract();
  const userAddress = useSelector((state) => state.userAddress);
  const web3wallet = useSelector((state) => state.web3);
  const { getSwapPathContract, getLiquidityBonusPercentage, getLiquidityBonus } = usePosition();

  const getWeb3 = () => {
    return web3wallet ? web3wallet : new Web3(rpcURL);
  };

  const getAllPools = () => {
    return pools;
  };

  const getPriceBINANCE = async (binanceapi) => {
    if (!binanceapi) return { price: 0 };
    try {
      const data = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${binanceapi}`
      );
      const json = await data.json();
      return json.price;
    } catch (err) {
      return { price: 0 };
    }
  };

  const getPriceGECKO = async (geckoapi) => {
    if (!geckoapi) return { price: 0 };
    try {
      const data = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${geckoapi}&vs_currencies=usd`
      );
      const json = await data.json();
      const price = json[geckoapi]["usd"];
      return { price: parseFloat(price).toFixed(3) };
    } catch (err) {
      return { price: 0 };
    }
  };

  const getUserPoolBalance = async (poolAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, userPoolBalance] = await callContract({
      contract,
      functionName: "users",
      args: [userAddress],
    });

    return userPoolBalance;
  };

  const getUserPoolData = async (contract, tokenContract, poolAddress) => {
    const web3 = getWeb3();

    const [
      userPoolBalance,
      correctedBalance,
      dividendWei,
      userBalanceWei,
      userAllowance,
    ] = await Promise.all([
      callContract({
        contract,
        functionName: "users",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "correctedBalanceOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "dividendsOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract: tokenContract,
        functionName: "balanceOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract: tokenContract,
        functionName: "allowance",
        args: [userAddress, poolAddress],
        getPromise: true,
      }),
    ]);

    const userBalance = (
      Math.floor(BigNumber(userBalanceWei).dividedBy(1e14)) / 10000
    ).toFixed(4);
    const userPoolBalanceEth = (
      Math.floor(
        BigNumber(userPoolBalance.balance.toString()).dividedBy(1e14)
      ) / 10000
    ).toFixed(4);
    const dividend = (
      Math.floor(BigNumber(dividendWei).dividedBy(1e12)) / 1000000
    ).toFixed(6);
    let userBalanceFull = BigNumber(userBalanceWei).dividedBy(1e18).toString();
    let userPoolBalanceFull = BigNumber(userPoolBalance.balance.toString())
      .dividedBy(1e18)
      .toString();

    if (userPoolBalanceFull.length > 17)
      userPoolBalanceFull = userPoolBalanceFull.substring(0, 17);
    if (userBalanceFull.length > 17)
      userBalanceFull = userBalanceFull.substring(0, 17);

    return {
      userBalance,
      userBalanceFull,
      userPoolBalanceFull,
      userPoolBalance: userPoolBalanceEth,
      isApproved: BigNumber(1e23).isLessThan(BigNumber(userAllowance)),
      dividend,
      correctedBalance,
    };
  };

  const getUserBalance = async (poolAddress) => {
    if (!web3wallet) return 0;
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);

    const [, userBalance] = await callContract({
      contract: tokenContract,
      functionName: "balanceOf",
      args: [userAddress],
    });

    return userBalance;
  };

  const isApproved = async (poolAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const [, userAllowance] = await callContract({
      contract: tokenContract,
      functionName: "allowance",
      args: [userAddress, poolAddress],
    });

    return BigNumber(1e23).isLessThan(BigNumber(userAllowance));
  };

  const getPoolData = async (poolAddress) => {
    const pool = pools.find((p) => p.address === poolAddress);
    const tokenAddress = tokenToAddress[pool.title];

    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const asset = pool.title;

    const calls = [
      callContract({
        contract: tokenContract,
        functionName: "balanceOf",
        args: [poolAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "outstandingLoans",
        getPromise: true,
      }),
      getInterestRate(tokenAddress),
      getInterestPoolShare(),
    ];

    if (userAddress) {
      calls.push(getUserPoolData(contract, tokenContract, poolAddress));
    }

    if (pool.binanceapi !== "stable") {
      if (pool.title === "ANCHOR") calls.push(getPriceGECKO(pool.geckoapi));
      else calls.push(getPriceBINANCE(pool.binanceapi));
    }

    const callData = await Promise.all(calls);

    const contractBalanceWei = callData[0];
    const loanWei = callData[1];
    const interestRate = parseFloat(callData[2]) / 10;
    const poolShareRate = callData[3];
    const userData = callData[4] || null;
    let price;

    if (pool.title === "ANCHOR")
      price = (callData[5] && callData[5].price) || 0;
    else price = callData[5] || 0;

    const loan = web3.utils.fromWei(loanWei.toString());
    const contractBalance = web3.utils.fromWei(contractBalanceWei.toString());

    let totalSupply = BigNumber(loanWei).plus(BigNumber(contractBalanceWei));
    const totalSupplyUSD = BigNumber(totalSupply)
      .multipliedBy(BigNumber(price.toString()))
      .dividedBy(1e18)
      .toFixed(4);

    const loanUSD = BigNumber(loanWei)
      .multipliedBy(BigNumber(price.toString()))
      .dividedBy(1e18)
      .toFixed(4);

    totalSupply = totalSupply.dividedBy(1e18).toString();

    const apy = (loan / totalSupply) * (interestRate * parseFloat(poolShareRate) / 100) || 0;

    //console.log('title, interestRate, loan/totalSupply, apy, poolShare',pool.title, interestRate, (loan / totalSupply), apy.toFixed(2), parseFloat(poolShareRate) / 100)
    const availableForLeverageUSD = (
      parseFloat(totalSupplyUSD) - parseFloat(loanUSD)
    ).toFixed(4);

    return {
      asset,
      poolAddress: poolAddress,
      tokenAddress,
      price,
      totalSupplyUSD,
      loanUSD,
      loan,
      contractBalance,
      totalSupply,
      userData,
      apy,
      interestRate,
      availableForLeverageUSD,
    };
  };

  const getNewPositionData = async (token, isShort, commitment, leverage) => {
    const fallback = {
      totalSize: 0,
      entryPrice: 0,
      impact: 0,
      liquidationFee: 0,
    };
    if (parseFloat(commitment) <= 0) return fallback;

    const contract = getSwapPathContract();
    const web3 = getWeb3();
    const convertedTokenAddress = tokenToAddress[token];

    let totalSize = parseFloat(commitment) * parseFloat(leverage);
    let liquidationFee = await getLiquidityBonus();
    const liquidityBonusPercentage = await getLiquidityBonusPercentage();
    let liquidationFeeTotal = (totalSize * liquidityBonusPercentage) / 1000 + liquidationFee;
    const amount = web3.utils.toWei(totalSize.toString());
    const oneEth = "1000000000000000000";

    //long position
    if (!isShort) {
      const [resultSwap, resultOneEth] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, amount],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const convertedTotalSize = web3.utils.fromWei(resultSwap);
      const convertedOneEthSize = web3.utils.fromWei(resultOneEth);
      const entryPrice = totalSize / convertedTotalSize;
      const oneEthPrice = 1 / convertedOneEthSize;

      let impact = ((entryPrice / oneEthPrice - 1) * 100).toFixed(3);

      if (impact < 0) impact = 0;
      return {
        totalSize,
        entryPrice,
        impact,
        liquidationFee: liquidationFeeTotal,
      };
    }
    //short position
    else {
      const [resultSwap, resultOneEth] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, amount],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const [resultSwapRev, resultOneEthRev] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [convertedTokenAddress, baseTokenAddress, resultSwap],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [convertedTokenAddress, baseTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const convertedTotalSize = web3.utils.fromWei(resultSwap);
      //const convertedOneEthSize = web3.utils.fromWei(resultOneEth);
      //const entryPrice = totalSize / convertedTotalSize;
      //const oneEthPrice = 1 / convertedOneEthSize;

      const convertedTotalSizeRev = web3.utils.fromWei(resultSwapRev);
      const convertedOneEthSizeRev = web3.utils.fromWei(resultOneEthRev);
      const entryPriceRev = convertedTotalSize / convertedTotalSizeRev;
      const oneEthPriceRev = 1 / convertedOneEthSizeRev;

      let impact = parseInt((entryPriceRev / oneEthPriceRev - 1) * 100);
      if (impact < 0) impact = 0;

      return {
        totalSize: convertedTotalSize,
        entryPrice: 1 / entryPriceRev,
        impact,
        liquidationFee: liquidationFeeTotal,
      };
    }
  };

  const getAvailableSupply = async (token, isShort) => {
    const tokenAddress = isShort ? tokenToAddress[token] : baseTokenAddress;
    const poolAddress = isShort
      ? pools.find((p) => p.title === token).address
      : baseTokenPoolAddress;
    const web3 = getWeb3();
    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const [err, availableSupplyWei] = await callContract({
      contract: tokenContract,
      functionName: "balanceOf",
      args: [poolAddress],
    });

    //return availableSupplyWei;
    const availableSupply = BigNumber(availableSupplyWei)
      .dividedBy(1e18)
      .toFixed(3);
    return availableSupply;
  };

  const deposit = async (poolAddress, amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString());
    const result = await sendContract(contract, {
      functionName: "deposit",
      arguments: [amountWei],
    });
    return result;
  };

  const withdraw = async (poolAddress, amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString());
    const result = await sendContract(contract, {
      functionName: "withdraw",
      arguments: [amountWei],
    });
    return result;
  };

  const approveToken = async (poolAddress) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3wallet.eth.Contract(erc20abi, tokenAddress);
    const result = await sendContract(tokenContract, {
      functionName: "approve",
      arguments: [poolAddress, "99999999999999999999999999999"],
    });
    return result;
  };

  const claimReward = async (poolAddress) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const result = await sendContract(contract, {
      functionName: "claim",
    });
    return result;
  };

  const getMaxLeverage = async (token) => {
    const web3 = getWeb3();
    const tokenAddress = tokenToAddress[token];
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      const leverage = await contract.methods
        .getMaxLeverage(tokenAddress)
        .call();
      return leverage;
    } catch {
      return 5;
    }
  };

  const getInterestPoolShare = async () => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      const poolShare = await contract.methods.poolFeeShare().call();
      return poolShare;
    } catch {
      return 66;
    }
  };

  const getInterestRate = async (tokenAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      const leverage = await contract.methods
        .getInterestRate(tokenAddress)
        .call();
      return leverage;
    } catch (err) {
      console.log(err)
      return 30;
    }
  };

  return {
    getAllPools,
    getPoolData,
    getNewPositionData,
    getAvailableSupply,
    deposit,
    withdraw,
    approveToken,
    isApproved,
    getUserBalance,
    claimReward,
    getUserPoolBalance,
    getMaxLeverage,
    getInterestRate,
  };
};

export default usePool;
