import { swapTokens } from "../constants/contracts";
import projectConfig from '_customization/project-config'

const usePrice = () => {


  const getPriceChartData = async (tokenA, tokenB, days = "1") => {
    if(tokenA === "ANCHOR" || tokenB === "ANCHOR"){
      const price = await getPriceChartDataGECKO(tokenA, tokenB, days);
      return price;
    }
    const price = await getPriceChartDataBINANCE(tokenA, tokenB, days);
    return price;
  }

  const getPriceChartDataGECKO = async (tokenA, tokenB, days = "1") => {
    const tokenAid = swapTokens[tokenA].geckoId;
    const tokenBid = swapTokens[tokenB].geckoId;

    const tokenAapiCode = tokenAid !== 'stable' ? tokenAid : 'tether';
    const tokenBapiCode = tokenBid !== 'stable' ? tokenBid : 'tether';

    try {
      const [dataA, dataB] = await Promise.all([
        fetch(`https://api.coingecko.com/api/v3/coins/${tokenAapiCode}/ohlc?vs_currency=usd&days=${days}`),
        fetch(`https://api.coingecko.com/api/v3/coins/${tokenBapiCode}/ohlc?vs_currency=usd&days=${days}`),
      ]);

      const [resultA, resultB] = await Promise.all([
        dataA.json(),
        dataB.json(),        
      ]);


      let tokenAData = (tokenAid !== 'stable') ? resultA.map(entry => [entry[0],entry[4]]) : resultA.map(entry => [entry[0],1/entry[4]]);
      let tokenBData = (tokenBid !== 'stable') ? resultB.map(entry => [entry[0],entry[4]]) : resultB.map(entry => [entry[0],1/entry[4]]);
      let prices = [];

      if(tokenAData.length !== tokenBData.length){
        if(tokenAData.length > tokenBData.length){
          tokenAData = tokenAData.slice(-tokenBData.length);
        }
        else{
          tokenBData = tokenBData.slice(-tokenAData.length);
        }
      }

      for (let i = 0; i < tokenAData.length; i++) {
        const priceA = tokenAData[i][1];
        const priceB = tokenBData[i][1];
        const timestamp =  tokenAData ? tokenAData[i][0] : tokenBData[i][0];
        prices[i] =
          priceB !== 0
            ? [timestamp, parseFloat(priceA) / parseFloat(priceB)]
            : 0;
      }
      return prices;
    } catch (err) {
      return null;
    }
  };

  const getPriceChartDataBINANCE = async (tokenA, tokenB, days = "1") => {
    const tokenAid = swapTokens[tokenA].binanceId;
    const tokenBid = swapTokens[tokenB].binanceId;

    const tokenAapiCode = tokenAid !== 'stable' ? tokenAid : 'USDCUSDT';
    const tokenBapiCode = tokenBid !== 'stable' ? tokenBid : 'USDCUSDT';


    let interval = "1h";
    if (days.toString() === "7") {
      interval = "1d";
    } else if (days.toString() === "30") {
      interval = "1d";
    } else if (days.toString() === "365") {
      interval = "1M";
    }

    const startTime = Math.ceil(new Date(Date.now() - 86400000 * parseInt(days)));

    try {
      const [dataA, dataB] = await Promise.all([
        fetch(`https://api.binance.com/api/v3/klines?symbol=${tokenAapiCode}&interval=${interval}&startTime=${startTime}&limit=1000`),
        fetch(`https://api.binance.com/api/v3/klines?symbol=${tokenBapiCode}&interval=${interval}&startTime=${startTime}&limit=1000`),
      ]);

      const [resultA, resultB] = await Promise.all([
        dataA.json(),
        dataB.json(),        
      //  priceDataA.json(),
      //  priceDataB.json(),
      ]);

      const tokenAData = (tokenAid !== 'stable') ? resultA.map(entry => [entry[0],entry[4]]) : resultA.map(entry => [entry[0],1/entry[4]]);
      const tokenBData = (tokenBid !== 'stable') ? resultB.map(entry => [entry[0],entry[4]]) : resultB.map(entry => [entry[0],1/entry[4]]);
      let prices = [];

      const length =  tokenAData ? tokenAData.length : tokenBData.length;
      for (let i = 0; i < length; i++) {
        const priceA = tokenAData[i][1];
        const priceB = tokenBData[i][1];
        const timestamp =  tokenAData ? tokenAData[i][0] : tokenBData[i][0];
        prices[i] =
          priceB !== 0
            ? [timestamp, parseFloat(priceA) / parseFloat(priceB)]
            : 0;
      }
      //prices.push([Date.now(), parseFloat(priceA.price) / parseFloat(priceB.price)])
      return prices;
    } catch (err) {
      return null;
    }
  };

  const getPairPriceChange = async (tokenA, tokenB) => {
    if(tokenA === "ANCHOR" || tokenB === "ANCHOR"){
      const price = await getPairPriceChangeGECKO(tokenA, tokenB);
      return price;
    }
    const price = await getPairPriceChangeBINANCE(tokenA, tokenB);
    return price;
  }

  const getPairPriceChangeGECKO = async (tokenA, tokenB) => {
    const tokenAid = swapTokens[tokenA].geckoId;
    const tokenBid = swapTokens[tokenB].geckoId;
    const tokenAapiCode = tokenAid !== 'stable' ? tokenAid : 'tether';
    const tokenBapiCode = tokenBid !== 'stable' ? tokenBid : 'tether';
    try {
      const [dataA, dataB] = await Promise.all([
        fetch(`https://api.coingecko.com/api/v3/coins/${tokenAapiCode}/ohlc?vs_currency=usd&days=1`),
        fetch(`https://api.coingecko.com/api/v3/coins/${tokenBapiCode}/ohlc?vs_currency=usd&days=1`)
      ])
      const [resultA, resultB] = await Promise.all([
        dataA.json(),
        dataB.json()
      ]);

      const priceAopen = (tokenAid !== 'stable') ? resultA[0][4] : 1;
      const priceBopen = (tokenBid !== 'stable') ? resultB[0][4] : 1;
      const priceAclose = (tokenAid !== 'stable') ? resultA[resultA.length - 1][4] : 1;
      const priceBclose = (tokenBid !== 'stable') ? resultB[resultB.length - 1][4] : 1;

      const priceOld =
        parseFloat(priceAopen) / parseFloat(priceBopen);
      const priceNew =
        parseFloat(priceAclose) / parseFloat(priceBclose);
      const priceChange = priceNew - priceOld;
      const changePercentage = 100 * priceChange / priceOld;

      return { priceChange, changePercentage };
    } catch (err) {
      return null;
    }
  };

  const getPairPriceChangeBINANCE = async (tokenA, tokenB) => {
    const tokenAid = swapTokens[tokenA].binanceId;
    const tokenBid = swapTokens[tokenB].binanceId;
    const tokenAapiCode = tokenAid !== 'stable' ? tokenAid : 'USDCUSDT';
    const tokenBapiCode = tokenBid !== 'stable' ? tokenBid : 'USDCUSDT';
    try {
      const [dataA, dataB] = await Promise.all([
        fetch(`https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenAapiCode}`),
        fetch(`https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenBapiCode}`)
      ])
      const [resultA, resultB] = await Promise.all([
        dataA.json(),
        dataB.json()
      ]);
      
      const priceAopen = (tokenAid !== 'stable') ? resultA.openPrice : 1;
      const priceBopen = (tokenBid !== 'stable') ? resultB.openPrice : 1;
      const priceAclose = (tokenAid !== 'stable') ? resultA.lastPrice : 1;
      const priceBclose = (tokenBid !== 'stable') ? resultB.lastPrice : 1;

      const priceOld =
        parseFloat(priceAopen) / parseFloat(priceBopen);
      const priceNew =
        parseFloat(priceAclose) / parseFloat(priceBclose);
      const priceChange = priceNew - priceOld;
      const changePercentage = 100 * priceChange / priceOld;

      return { priceChange, changePercentage };
    } catch (err) {
      return null;
    }
  };

  const getPriceChange = async (tokenA) => {
    if(tokenA === "ANCHOR"){
      const price = await getPriceChangeGECKO(tokenA);
      const anchorPrice = await getCurrentAnchorPrice();
      price.currentPrice = anchorPrice;
      return price;
    }
    const price = await getPriceChangeBINANCE(tokenA);
    return price;
  }

  const getCurrentAnchorPrice = async () => {
    const data = await fetch(projectConfig.api.getCurrentTokenPrice);
    const priceData = await data.json();
    return priceData;
  }

  const getPriceChangeGECKO = async (tokenA) => {
    const tokenAid = swapTokens[tokenA].binanceId;
    try{
      const data = await fetch(
        `https://api.coingecko.com/api/v3/coins/${tokenAid}/ohlc?vs_currency=usd&days=1`
      );
      const priceData = await data.json();

      
      const currentPrice = parseFloat(priceData[priceData.length - 1][4]);
      const oldPrice = parseFloat(priceData[0][4]);
  
      const changePercentage = currentPrice / oldPrice;
      const priceChange = currentPrice - oldPrice;

      return { currentPrice, priceChange, changePercentage };
    }
    catch{
      return { currentPrice:0, priceChange:0, changePercentage:0 };
    }
  };

  const getPriceChangeBINANCE = async (tokenA) => {
    const tokenAid = swapTokens[tokenA].binanceId;
    if(tokenAid === 'stable') return { currentPrice:1, priceChange:0, changePercentage:0 };
    try{
      const data = await fetch(
          `https://api.binance.com/api/v3/ticker/24hr?symbol=${tokenAid}`
        );
      const priceData = await data.json();
      if (!priceData.hasOwnProperty("priceChangePercent")) return 0;
  
      const changePercentage = parseFloat(priceData.priceChangePercent);
      const currentPrice = parseFloat(priceData.lastPrice);
      const priceChange = parseFloat(priceData.priceChange);

      return { currentPrice, priceChange, changePercentage };
    }
    catch{
      return 0;
    }
  };

  return { getPriceChartData, getPairPriceChange, getPriceChange };
};

export default usePrice;