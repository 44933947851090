import classNames from 'classnames';
import { useRef, useState } from 'react';
import styles from './swap-input.module.css'
import { ReactComponent as ExpandMoreIcon } from 'assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'assets/expand-less.svg';
import TokenIcon from 'components/tokenIcon/tokenIcon';
import useOutsideDetector from "hooks/useOutsideDetector";
import { isNumeric, numberWithCommas } from 'utils/libs';
import { useTranslation } from 'react-i18next';

function SwapInput({ label, list, onSelected, deactiveToken, selectedValue, amount, onAmountChange, balance }) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    useOutsideDetector(ref, () => { setShow(false) });
    function tokenSelected(item) {
        if (item != deactiveToken) {
            setShow(false);
            onSelected(item);
        }
    }
    return <div className={styles.container}>

        <div className={styles.row1}>
            <div className={styles.col1}>
                <div className={styles.label}>{label}</div>
                <input type='number' className={styles.input} value={amount} onChange={(evt) => { onAmountChange(evt.target.value); }} />
            </div>
            <div className={classNames(styles.col2, styles.noselect)}>
                <div>{t("swapInputxxliveChart.balance_text")}: {balance && numberWithCommas(Number(balance)?.toFixed(4))}</div>
                <div className={styles.col21}>
                    {selectedValue && <TokenIcon className={styles.tokenIcon} tokenName={selectedValue} />}
                    <div>{selectedValue}</div>
                    {show ? <ExpandLessIcon onClick={() => setShow(!show)} className={styles.expandIcon} /> : <ExpandMoreIcon onClick={() => setShow(!show)} className={styles.expandIcon} />}
                </div>
            </div>
        </div>

        {show && <div ref={ref} className={classNames(styles.options, styles.noselect)}>
            {list?.map((item, ix) => <div key={ix} className={styles.option} onClick={() => { tokenSelected(item) }}>
                <TokenIcon className={styles.tokenIcon} tokenName={item} />
                <div className={classNames(deactiveToken == item && styles.deactiveToken)}>{item}</div>
            </div>)}
        </div>}
    </div>
}
export default SwapInput;