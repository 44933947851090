import styles from './live-chart.module.css'
import TokenIcon from 'components/tokenIcon/tokenIcon';
import { ReactComponent as RightLeftIcon } from 'assets/right-left-solid.svg';
import ReactApexChart from 'react-apexcharts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import usePrice from 'hooks/usePrice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { numberWithCommas } from 'utils/libs'
import { useTranslation } from 'react-i18next';

function LiveChart() {
    const {t} = useTranslation();
    const { getPriceChartData, getPairPriceChange } = usePrice();
    const swapFromToken = useSelector((state) => state.swapFromToken);
    const swapToToken = useSelector((state) => state.swapToToken);
    const [data, setData] = useState([]);
    const [priceChange, setPriceChange] = useState(null);
    const [changePercentage, setChangePercentage] = useState(null);
    const periods = { d: "1", w: "7", m: "30", y: "365" };
    const [periodItem, setPeriodItem] = useState(periods.w);
    const dispatch = useDispatch();
    function swapTokens() {
        dispatch({ type: "swapTokens" });
    }
    useEffect(() => {

        if (swapFromToken && swapToToken) {
            getPriceChartData(swapFromToken, swapToToken, periodItem).then((res) => {
                if (Array.isArray(res)) {
                    const lst = res.map((r) => {
                        var format = "YY-D-M H:m";
                        if (periodItem == periods.d) {
                            //format = "ddd hh:mm A";
                        }
                        if (periodItem == periods.w) {
                            //format = "ddd h:mm";
                        }
                        if (periodItem == periods.m) {
                            //format = "D MMM h:m";
                        }
                        if (periodItem == periods.y) {
                            //format = "YY D MMM h:m";
                        }

                        return { x: moment(new Date(r[0])).format(format), y: r[1] };
                    });
                    setData(lst);
                }
            });
            getPairPriceChange(swapFromToken, swapToToken).then((res) => {
                if (res) {
                    setPriceChange(res.priceChange);
                    setChangePercentage(res.changePercentage);
                }
            });
        } else {
            setData([]);
            setPriceChange(null);
            setChangePercentage(null);
        }
    }, [swapFromToken, swapToToken, periodItem]);
    ///////
    const options = {
        height: '100%',
        stroke: {
            curve: 'smooth',
            width: 1
        },
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            type: 'area'
        },
        toolbar: {
            show: false
        },
        grid: {
            borderColor: 'var(--text-color-secondary)',
            strokeDashArray: 3,
            yaxis: {
                lines: {
                    show: false
                }
            },
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['var(--text-color-secondary)'],
        fill: {
            colors: ['var(--text-color-secondary)', 'var(--negative-growth)'],
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.5,
                opacityTo: 0
            }
        },
        xaxis: {
            labels: {
                style: {
                    cssClass: styles.chartLabel
                },
            },

            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            show: false,
            labels: {
                style: {
                    cssClass: styles.chartLabel
                },
            },

        },
        tooltip: {
            style: {
                fontSize: '1.4rem'
            }
        }
    }
    var series = [{
        data: data
    }];

    function setPeriod(item) {
        setPeriodItem(item);
    }
    return <div className={styles.container}>
        <div className={styles.row1}>
            <div className={styles.title}>
                <div>{t("swapInputxxliveChart.liveChart_text")}</div>
                <div className={styles.val1}>{data.length > 0 ? numberWithCommas(data[data.length - 1]?.y?.toFixed(4)) : ''}</div>
            </div>
            <RightLeftIcon className={styles.icon} onClick={swapTokens} />
            <div className={styles.tokens} >
                <TokenIcon tokenName={swapFromToken} className={styles.tokenIcon1} />
                <TokenIcon tokenName={swapToToken} className={styles.tokenIcon2} />
            </div>
        </div>
        {(swapFromToken && swapToToken) && <div className={styles.row2}>
            <div>{swapFromToken}/{swapToToken}</div>
            <div className={priceChange < 0 ? styles.red : styles.green}>{numberWithCommas(priceChange?.toFixed(2))}({changePercentage?.toFixed(2)}%)</div>
        </div>}
        <div className={styles.row3}>
            {moment(new Date()).format("MMM DD, YYYY, HH:mm A")}
        </div>
        <div className={styles.row4}>
            <div className={styles.selectPeriod}>
                <div onClick={() => setPeriod(periods.d)} className={classNames(styles.periodItem, periodItem == periods.d && styles.selectedPeriodItem)}>24H</div>
                <div onClick={() => setPeriod(periods.w)} className={classNames(styles.periodItem, periodItem == periods.w && styles.selectedPeriodItem)}>1W</div>
                <div onClick={() => setPeriod(periods.m)} className={classNames(styles.periodItem, periodItem == periods.m && styles.selectedPeriodItem)}>1M</div>
                <div onClick={() => setPeriod(periods.y)} className={classNames(styles.periodItem, periodItem == periods.y && styles.selectedPeriodItem)}>1Y</div>
            </div>
            <div className={styles.chart}>
                <ReactApexChart options={options} type='area' series={series} height='100%' />
            </div>
        </div>
    </div>
}
export default LiveChart;