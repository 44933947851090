import companyLogo from "_customization/assets/project-company-logo.png";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.css";
import projectConfig from '_customization/project-config'

function Footer() {
  const { t } = useTranslation();
  const { terms, dataPrivacy } = projectConfig.externalLinks;
  return (
    <div className={styles.container}>
      <div className={styles.terms}>
        <a href={terms} target="_blank">
        {t("footer.terms_text")}
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href={dataPrivacy} target="_blank">
        {t("footer.privacy_text")}
        </a>
      </div>

      <div>{t("footer.powered_text")}</div>
      <img src={companyLogo} className={styles.pwbyimg} />
    </div>
  );
}
export default Footer;
