import classnames from "classnames";
import Help from "components/help/help";
import TokenIcon from "components/tokenIcon/tokenIcon";
import usePosition from "hooks/usePosition";
import useSwap from "hooks/useSwap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "utils/libs";
import styles from "./topup.module.css";
import Button from 'components/_shared/button/Button'

function Topup() {
  const {t} = useTranslation();
  const [selected, setSelected] = useState("Deposit");
  const {
    isApprovedMargin,
    approveTokenMargin,
    depositMargin,
    withdrawMargin,
  } = usePosition();
  const { getTokenBalance } = useSwap();
  const [isApproved, setIsApproved] = useState(false);
  const [amount, setAmount] = useState("");
  const [busdBalance, setBusdBalance] = useState();
  const [updateApprove, setUpdateApprove] = useState(true);
  const [updateBalance, setUpdateBalance] = useState(true);
  const userAddress = useSelector((state) => state.userAddress);
  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.web3);
  const BUSDBalance = useSelector((state) => state.BUSDBalance);
  const balance = selected == "Withdraw" ? BUSDBalance : busdBalance;
  const selectedPool = useSelector((state) => state.selectedPool);
  const [approving, setApproving] = useState(false);
  const [confirming, setConfirming] = useState(false);

  useEffect(() => {
    if (userAddress && web3) {
      getTokenBalance("BUSD", false).then((res) => {
        if (res) setBusdBalance(res);
      });
      setApproving(true);
      isApprovedMargin().then((res) => {
        setApproving(false);
        if (typeof res == "boolean") setIsApproved(res);
      });
    }
  }, [updateApprove, updateBalance, selectedPool, userAddress, web3]);

  function transfer() {
    if (isApproved && !isNaN(amount)) {
      if (selected == "Withdraw") {
        setConfirming(true);
        withdrawMargin(amount).then((res) => {
          setConfirming(false);
          if (res.status == true)
            dispatch({
              type: "showMessageChange",
              showMessage: true,
              txlink: res.txlink,
              messageTitle: t("transactionPopups.TransactionConfirmed_text"),
            });
          dispatch({ type: "balanceUpdateChange" });
          setAmount("");
          setUpdateBalance(!updateBalance);
        });
      }
      if (selected == "Deposit") {
        setConfirming(true);
        depositMargin(amount).then((res) => {
          setConfirming(false);
          if (res.status == true)
            dispatch({
              type: "showMessageChange",
              showMessage: true,
              txlink: res.txlink,
              messageTitle: t("transactionPopups.TransactionConfirmed_text"),
            });
          dispatch({ type: "balanceUpdateChange" });
          setAmount("");
          setUpdateBalance(!updateBalance);
        });
      }
    }
  }

  var textCss = [null, styles.unselected];
  if (selected == "Withdraw") {
    textCss = [styles.unselected, null];
  } else if (selected == "Deposit") {
  }

  return (
    <div className={styles.box}>
      <div className={styles.row1}>
      {t('topup.LeverageTop_text')}
        <Help
          shortMessage=
          {t('topup.shortMessage_text')}
          longMessage={t('topup.longMessage_text')}
        />
      </div>

      <TokenIcon tokenName="BUSD" className={styles.image} />
      <div className={styles.headerRow}>
        <div
          className={styles.headerCol}
          onClick={() => {
            setSelected("Deposit");
          }}
        >
          <div className={textCss[0]}>{t('topup.deposit_text')}</div>
          <hr
            className={classnames(
              styles.hr,
              selected == "Withdraw" && styles.withdrawSelect
            )}
          />
        </div>
        <div
          className={styles.headerCol}
          onClick={() => {
            setSelected("Withdraw");
          }}
        >
          <div className={textCss[1]}>{t('topup.withdraw_text')}</div>
        </div>
      </div>
      <div className={styles.inputParent}>
        <input
          className={styles.input}
          value={amount}
          onChange={(evt) => {
            setAmount(evt.target.value);
          }}
        />
        <button className={styles.maxBtn} onClick={() => setAmount(balance)}>
        {t('topup.Max_text')}
        </button>
      </div>
      <div className={styles.buttonParent}>
        {isApproved ? (
          <div className={styles.usdtBalance}>
            Balance: &nbsp;{numberWithCommas(Number(balance).toFixed(2))}
            &nbsp;BUSD
          </div>
        ) : (
          <Button
            disabled={isApproved || approving}
            onClick={() =>
              approveTokenMargin().then((res) => {
                if (res.status == true)
                  dispatch({
                    type: "showMessageChange",
                    showMessage: true,
                    txlink: res.txlink,
                    messageTitle: t("transactionPopups.Approved_text"),
                  });
                setUpdateApprove(!updateApprove);
              })
            }
          >
            {t('topup.approve_text')}
          </Button>
        )}
        <Button
          disabled={!isApproved || confirming}
          onClick={transfer}
        >
          {selected=='Withdraw'? t('topup.withdraw_text'): t('topup.deposit_text')}
        </Button>
      </div>
    </div>
  );
}
export default Topup;
